<template>
    <div class="d_bg" style="background-image: url('img/d_bg.png');">
        <div class="d_btn" style="background-image: url('img/d_btn.png');" :data-clipboard-text="code" @click="download()">
        </div>
        <!-- <div v-if="isIOS" class="by_btn">
            <div class="d_btn_1" :data-clipboard-text="code" @click="download1()">备用下载1</div>
            <div class="d_btn_2" :data-clipboard-text="code" @click="download2()">备用下载2</div>
        </div> -->
        <div class="mask_" v-if="showMask">
            <div class="mask_tip">请点击右上角，选择浏览器打开</div>
        </div>
    </div>
</template>
<script>
import "@/assets/css/index.css"
import Clipboard from "clipboard";
export default {
    data() {
        return {
            code: '',
            showMask: false,
            isIOS:false
        }
    },
    created() {
        let u = navigator.userAgent; 
        this.isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
        this.code = this.$route.fullPath.split('?')[1]
        let ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            this.showMask = true
        }else{
            this.showMask = false
        }

    },
    methods: {
        download() {
            //  window.location.href = 
            var clipboard = new Clipboard('.d_btn')
            clipboard.on('success', e => {
                // 释放内存
                clipboard.destroy()
            })
            clipboard.on('error', e => {
                // 释放内存
                clipboard.destroy()
            })
            this.judgeClient()
        },
        // download1() {
        //     //  window.location.href = 
        //     var clipboard = new Clipboard('.d_btn_1')
        //     clipboard.on('success', e => {
        //         // 释放内存
        //         clipboard.destroy()
        //     })
        //     clipboard.on('error', e => {
        //         // 释放内存
        //         clipboard.destroy()
        //     })
        //     window.location.href = 'itms-services://?action=download-manifest&url=https://pro.guangxiximantian.com/download/dian_standby.plist'
        // },
        // download2() {
        //     //  window.location.href = 
        //     var clipboard = new Clipboard('.d_btn_2')
        //     clipboard.on('success', e => {
        //         // 释放内存
        //         clipboard.destroy()
        //     })
        //     clipboard.on('error', e => {
        //         // 释放内存
        //         clipboard.destroy()
        //     })
        //     window.location.href = 'itms-services://?action=download-manifest&url=https://pro.guangxiximantian.com/download/dian_standby2.plist'
        // },
        judgeClient() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
            if (isAndroid) {
                 window.location.href= 'http://cdn.whsnkj.shop/xiazai/happy_gf.apk'
            } else if (isIOS) {
                 window.location.href = 'itms-services://?action=download-manifest&url=https://api.wuhanshuiningkeji.cn/download/dian.plist'
            } else {
                return 'PC';
            }
        }                              
    }
}
</script>

